.adventure-details-container {
  min-height: 33vh;
  transition: min-height 0.2s ease-in-out;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
}

.adventure-details-container.hidden {
  min-height: 0;
  height: 0;
}

.adventures-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
  /* padding: 1rem; */
  box-sizing: border-box;
}

.adventure-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden scroll;
  padding: 1rem 0;
}

@media (max-width: 900px) {
  .adventure-details-container {
    min-height: 50dvh;
  }
}
