.adventure-tile {
  background-size: contain;
  display: flex;
  justify-content: stretch;
  transform-origin: 50% 50%;
  transition: transform 0.2s ease-in-out;
  aspect-ratio: 1;
  width: 300px;
}

.adventure-tile.selected {
  transform: scale(1.5);
}

.tile-text {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  text-shadow: 0px 0px #000;
  transition: background-color 0.2s ease-in-out, text-shadow 0.2s ease-in-out;
}

.adventure-tile.selected .tile-text {
  background-color: rgba(0, 0, 0, 0);
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000;
}

@media (hover: hover) {
  .adventure-tile.base:hover {
    transform: scale(1.2);
  }
}

@media (max-width: 660px) {
  .adventure-tile {
    width: 150px;
  }

  .tile-text {
    font-size: 1rem;
  }
}
