.adventure-details {
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.adventure-details a,
.adventure-details a:visited,
.adventure-details a:hover {
  color: white;
}

.episode-select-container {
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  max-width: 100%;
  height: 94px;
  max-height: 94px;
  box-sizing: border-box;
}

.episode-select-btn {
  padding: 0.5rem 1rem;
  flex-shrink: 0;
  border: none;
  background-color: #092837;
  color: white;
  font-weight: bold;
  box-sizing: content-box;
  border: 2px solid rgba(0, 0, 0, 0);
  transition: all 0.2s ease-in-out;
}

.episode-select-btn.selected {
  transform: scale(1.4);
  border: 2px solid white;
}

.details-grid {
  display: grid;
  padding: 0 2rem;
  grid-template-columns: repeat(2, fit-content(100%));
  gap: 1rem 2rem;
  max-height: calc(100% - 94px);
  overflow: hidden auto;
}

.spotify-link {
  background-color: #1db954;
  background-image: url("~/public/Spotify_Icon_RGB_White.png");
  background-size: contain;
  background-repeat: no-repeat;
  border: 0.5rem solid #1db954;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.25rem 0.5rem 0.25rem 2.5rem;
  text-decoration: none;
  border-radius: 50px;
  margin-top: 0.5rem;
  box-sizing: border-box;
}

@media (max-width: 900px) {
  .details-grid {
    grid-template-columns: 1fr;
    gap: 0;
    max-height: calc(100% - 55px);
  }
  .episode-select-container {
    padding: 0 1rem;
    overflow: auto hidden;
    justify-content: unset;
    flex-wrap: nowrap;
    max-width: 100%;
    height: 55px;
    max-height: 55px;
  }
}
